import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


const getItemStyle = (isDragging, draggableStyle, currentI, thisI) => ({

  // change background colour if dragging
  background: isDragging ? "var(--colour-teal)" : "white",
  border: currentI === thisI ? "2px solid red" : "1px solid var(--border-colour)",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "var(--off-med-grey)" : "var(--med-grey)",
});

class Filmstrip extends React.Component {

  state = {
    loading: true,
    // slides: [],
    // currentPage: 0,
  }

  componentDidMount = () => {
    this.setState({
      // slides: this.props.slides,
      loading: false,
      // currentPage: this.props.currentPage
    })
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.slides,
      result.source.index,
      result.destination.index
    );

    let newCurrent = this.props.currentPage;

    if(this.props.currentPage === result.source.index){
      newCurrent = result.destination.index
    } else if ((this.props.currentPage > result.source.index && this.props.currentPage <= result.destination.index)) {
      newCurrent--;
    } else if ((this.props.currentPage < result.source.index && this.props.currentPage >= result.destination.index)) {
      newCurrent++;
    } else if ((this.props.currentPage > result.source.index && this.props.currentPage > result.destination.index) || (this.props.currentPage < result.source.index && this.props.currentPage < result.destination.index)){
      //no change moving below or above
    }

    // this.setState({
    //   slides: items,
    //   currentPage: newCurrent,
    // })
    this.props.updateSlideOrder(items, newCurrent);
  }

  onClick = (slideNumber) => {
    // this.setState({ currentPage: slideNumber })
    this.props.changeSlide(slideNumber);
  }

  render () {
    if(this.state.loading) return(<p>loading</p>)
    return(
        <div className="film-strip">

        <div className="editor-tools">
                {/* <button onClick={() => this.changeSlide(this.state.currentPage-1)}>
                    <img src="/static/icons/left.svg" alt="" />
                    <p>Previous</p>
                </button>
                <p className="slide-number">Slide Number: {this.state.currentPage+1} / {this.state.slideCount}</p>
                <button onClick={() => this.changeSlide(this.state.currentPage+1)}>
                    <img src="/static/icons/right.svg" alt="" />
                    <p>Next</p>
                </button> */}
                <button onClick={this.props.addSlide}>
                    <img src="/static/icons/add.svg" alt="" />
                    <p>Add</p>
                </button>
                <button onClick={this.props.delSlide}>
                    <img src="/static/icons/trash.svg" alt="" />
                    <p>Delete</p>
                </button>
            </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
            {(provided, snapshot) => (
            <div
              className="film-strip__inner"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
            {this.props.slides.map((slide, i) => ( 
              <Draggable key={i} draggableId={i.toString()} index={i}>
                  {(provided, snapshot) => (
                    <div
                    onClick={() => {this.onClick(i)}}
                      className="film-strip__item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        this.props.currentPage,
                        i,
                      )}
                    >
                      <p className="film-strip__number">{i+1}</p>
                      <p className="film-strip__content">{slide.text}</p>
                    </div>
                  )}
                </Draggable>
            ))}
             {provided.placeholder}
            </div>
            )}
            </Droppable>
          </DragDropContext>
        </div>
    )
  }
}


export default Filmstrip;
