import React, { Component } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ShadowDoc from '../components/ShadowDoc';


class Download extends Component {

    state = {
        loading: true,
        content: {},
        noContent: false,
        render: false,
    }

    componentDidMount(){
        if(localStorage.getItem('slides') === null){
            this.setState({
                noContent: true
            });
        } else {
            let slides = JSON.parse(localStorage.getItem('slides'));
            this.setState({
                content: slides,
                loading: false
            });
        }
    }
    triggerRender = () => {
        this.setState({
            render: true
        })
    }

    render(){
        if (this.state.noContent){
            return(
                <p>No Content</p>
            ) 
        }
        else if (this.state.loading === true){
            return(
                <p>loading</p>
            )
        }
        let document = this.state.content;
        let currentDateString = new Date().getTime();
        let fileNameString = document.title + "_" + currentDateString + '.pdf';

        if(!this.state.render){
            return(
                <div className="create-home">
                    <div className="page-button" onClick={this.triggerRender}><img src="/static/icons/download.svg" alt="export pdf" /><span>Download<br/>{document.title}</span></div>
                {/* <PDFViewer style={{width: '100%', height: '600px'}}>
                    <ShadowDoc title={document.title} pageList={document.pageList} />
                </PDFViewer>  */}
                </div>
            )
        } else {

            return(
            <div className="create-home">

                    <PDFDownloadLink document={<ShadowDoc title={document.title} pageList={document.pageList} />} fileName={fileNameString} className="page-button">
                        {({ blob, url, loading, error }) => (loading ? 
                        <span className="loading-text">Preparing Download</span> : 
                        <div><img src="/static/icons/download.svg" alt="export pdf" /><span>Document Ready!<br/>Download {document.title}</span></div>
                        )}
                    </PDFDownloadLink>
                
            </div>
            )
        }
    }
}

export default Download;