import React from 'react';
import TextArea from './TextArea';
import ImageGallery from './Galleries/ImagerGallery.js';
import ImageLayout from './Layouts/ImageLayout';
import Filmstrip from '../components/Filmstrip'
import Search from '../components/search'
import Fuse from "fuse.js";

const updateLocal = (newDoc) => {
    localStorage.setItem("slides", JSON.stringify(newDoc))
} 

class SlideEditor extends React.Component {

    state = {
        loading: true,
        // content: {},
        currentPage: 0,
        // saved: false,
        slideCount: 0,
        document: {},
    }

    componentDidMount(){
        let slides = JSON.parse(localStorage.getItem('slides'));
        this.setState({
            // content: slides.pageList[this.state.currentPage],
            loading: false,
            slideCount: slides.pageList.length,
            document: slides,
            currentImages: this.props.images,
            fuse: new Fuse(this.props.images, {keys: ["tags", "title", "categories"], threshold: 0.2})
        });
    }

    searchWithFuse = (query) => {
        if (!query) {
            return false;
        }

        return this.state.fuse.search(query).map((result) => result.item);
    }

    setWithFuse = (query) => {
        let results

        if(query.length > 0){
            results = this.searchWithFuse(query);
        } else {
            results = this.props.images
        }

        this.setState({
            currentImages: results
        });

    }

    changeText = (content, field) => {
        let newState = Object.assign({}, this.state);
        newState.document.pageList[this.state.currentPage][field] = content;
        this.setState(newState);
        updateLocal(this.state.document);
    }

    changeImage = (newImage) => {
        let newDoc = this.state.document
        newDoc.pageList[this.state.currentPage].image = newImage;
        this.setState({
            document: newDoc
        });
        updateLocal(this.state.document);
    }
    
    changeSlide = (newSlide) => {
        if(newSlide >= 0 && newSlide < this.state.slideCount){
            this.setState({
                currentPage: newSlide,
                currentImages: this.props.images
            })
        }
    }

    addSlide = () => {
        let document = this.state.document;
        document.pageList.push({
            layout: "image",
            image: "",
            text: "Enter your text into the corresponding form on the right",
          });
        
        let newLength = document.pageList.length;
        this.setState({
            slideCount: newLength,
            currentPage: newLength - 1,
            // content: document.pageList[newLength - 1],
            document: document,
            currentImages: this.props.images,
        });
        updateLocal(document);
    }

    delSlide = () => {
        let r = window.confirm("Are you sure you want to delete this page?");
        if (!r) return null; 

        if (this.state.slideCount <= 1){
            alert("can't delete the last page in a document")
        } else {
            let document = this.state.document;
            document.pageList.splice(this.state.currentPage, 1);
            
            let newLength = document.pageList.length,
            newPage = this.state.currentPage-1
            this.setState({
                slideCount: newLength,
                currentPage: newPage,
                // content: document.pageList[newPage],
                document: document,
                currentImages: this.props.images,
            });
            updateLocal(document);
        }
    }

    updateSlideOrder = (list, newCurrent) => {
        let doc = this.state.document
         doc.pageList = list;
         this.setState({
             document: doc,
             currentPage: newCurrent,
             currentImages: this.props.images,
         });
         updateLocal(doc);
     }

  render () {
    if (this.state.loading === true){
        return(
            <p>loading</p>
        )
    }
    let slide = this.state.document.pageList[this.state.currentPage];
    return (
    <div className="slide-editor__wrap">

        <Filmstrip addSlide={this.addSlide} delSlide={this.delSlide} changeSlide={this.changeSlide} updateSlideOrder={this.updateSlideOrder} slides={this.state.document.pageList} currentPage={this.state.currentPage} />
        
        <div className="slide-editor__currentPage" key={this.state.currentPage}>
            <div className="slide-editor">
                <ImageLayout content={slide} />
            </div>
            <div className="data-entry">
                <div>
                    <h3>{this.state.currentImages.length} {this.state.currentImages.length === 1 ? 'Image' : 'Images'}</h3>
                    <Search searchWithFuse={this.setWithFuse} />
                    <ImageGallery title={true} images={this.state.currentImages} content={slide} changeImage={this.changeImage} />
                </div>
                <div>
                    <h3>Text</h3>
                    <TextArea changeText={this.changeText} field="text" initContent={slide.text} />
                </div>
            </div>
        </div>
    </div>
    );
  }
}


export default SlideEditor;



