import React, { Component } from 'react';
import SlideEditor from '../components/SlideEditor';
import defaultSlide from '../components/DefaultSlide';
import CreateHome from '../components/CreateHome';


class StoryEditor extends Component {

    state = {
        hasContent: false,
    }

    componentDidMount(){
        if (localStorage.getItem("slides") !== null) {
            let slides = JSON.parse(localStorage.getItem('slides'));
            if(slides.pageList.length > 0) this.setState({ 
                hasContent: true,
            });
        }
    }

    render(){
        if(!this.props.hasContent) return( <CreateHome createStory={this.props.addPages} createTemplate={this.props.createTemplate} /> )
        return(
            <>
                <SlideEditor images={this.props.images} defaultSlide={defaultSlide} />
            </>
        )}
}

export default StoryEditor;