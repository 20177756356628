import React, { Component } from 'react';
import './App.scss';
import NavBar from './NavBar';
import HeaderBar from './HeaderBar';
import StoryEditor from '../routes/StoryEditor';
import Download from '../routes/Download';
import {defaultSlide, templateSlides} from '../components/DefaultSlide';
import {
  BrowserRouter,
  Route,
} from 'react-router-dom';
import CreateHome from './CreateHome';
import {encode as base64_encode} from 'base-64'


const fetchImages = () => {
  let url = process.env.REACT_APP_IMG_URL;
  let username = process.env.REACT_APP_IMG_USR;
  let password = process.env.REACT_APP_IMG_PAS;

  let headers = new Headers();

  headers.set('Authorization', 'Basic ' + base64_encode(username + ":" + password));

  return fetch(url, {
      method: 'GET',
      headers: headers,
  })
   .then(resp => resp.json())
   .then(json => json)
   .catch(function(error) {
      console.log('error', error)
    })
}

class App extends Component {

  state = {
    title: 'MHIDI-Y Social Story Studio',
    hasContent: false,
  }

  componentDidMount(){
    let slides = JSON.parse(localStorage.getItem('slides'));
    fetchImages().then((data) => {
      if(slides !== null){
        this.setState({
            title: slides.title,
            hasContent: true,
            images: data,
        });
      } else {
        this.setState({
          images: data,
        });
      }
      
    });
  }


  changeTitle = () => {
    let checkSlides = JSON.parse(localStorage.getItem('slides'));
    if(checkSlides !== null){
      let r = window.confirm("Do you want to change the story title?");
      if (!r) return null; 

      var docTitle = prompt("Please name the story");
      if (docTitle !== null && docTitle !== "") {
          checkSlides.title = docTitle;
          localStorage.setItem('slides', JSON.stringify(checkSlides));
          this.setState({ 
            title: docTitle,
          });

      } else if (docTitle === ""){
          alert('please try again with a new story name');
      }
    }
  }

  addPages = () => {
    let checkSlides = JSON.parse(localStorage.getItem('slides'));
    if(checkSlides !== null){
    var r = window.confirm("This will delete your current story");
      if (r === true) {
      } else {
        return null;
      }
    }
    var docTitle = prompt("Please name the story");
    if (docTitle !== null && docTitle !== "") {
        let slides = {
            title: docTitle,
            pageList: [defaultSlide]
        }
        localStorage.setItem('slides', JSON.stringify(slides));
        this.setState({ 
          title: docTitle,
          hasContent:true 
        });

    } else if (docTitle === ""){
        alert('please try again with a new story name');
    }
  }

  addTemplate = () => {
    let checkSlides = JSON.parse(localStorage.getItem('slides'));
    if(checkSlides !== null){
    var r = window.confirm("This will delete your current story");
      if (r === true) {
      } else {
        return null;
      }
    }
    let docTitle = "Going for my heath check";
        let slides = {
            title: docTitle,
            pageList: templateSlides
        }
        localStorage.setItem('slides', JSON.stringify(slides));
        this.setState({ 
          title: docTitle,
          hasContent:true 
        });
  }

  render(){
    return(
          <BrowserRouter>
          <div className="app">
            <HeaderBar title={this.state.title} onClick={this.changeTitle} />
            <NavBar />
            <div className="page">
              <Route exact path="/" render={() => <CreateHome createStory={this.addPages} createTemplate={this.addTemplate} />} />
              <Route path="/story-editor" render={() => <StoryEditor images={this.state.images} hasContent={this.state.hasContent} addPages={this.addPages} createTemplate={this.addTemplate} />} />
              <Route path="/download" render={() => <Download />} />
            </div>
          </div>
          </BrowserRouter>
    )
  }
}

export default App;
