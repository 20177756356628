import React from 'react';
import {
  NavLink,
} from 'react-router-dom';

function NavBar() {
  return (
    <nav className="side-nav">
        <ul>
          <li><NavLink to='/story-editor' activeClassName="active">
            <img src="/static/icons/edit.svg" alt="" />
            <p>Editor</p>
          </NavLink></li>
          <li><NavLink to='/download' activeClassName="active">
            <img src="/static/icons/export.svg" alt="" />
            <p>Export</p>
          </NavLink></li>
        </ul>
    </nav>
  )
}

export default NavBar;




