import React from 'react';

function ImageGallery(props) {
    let slide = props.content;
    let images = props.images;

    if (Array.isArray(images) && images.length > 0) {

        return (
            <div className="data-entry__gallery photo-selector">
                <div className="photo-selector__gallery">
                    {images.map((option, i) => ( 
                        <div key={i} className={ (option.images[0] === slide.image) ? 'selected' : 'not-selected' } onClick={() => props.changeImage(option.images[0])}>
                            <img src={option.images[0]} alt={option.title}></img>
                            <span>{option.title}</span>
                        </div>
                    ))}
                </div>
            </div>
        )

    }
    return (
        <div className="data-entry__gallery photo-selector">
            <div className="photo-selector__gallery">
                <p>no results found</p>
            </div>
        </div>
    )
    
}

export default ImageGallery;