import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer';


Font.register({
  family: 'Open Sans',
  fonts: [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
  ]
});

Font.registerHyphenationCallback(word => (
  [word]
));

// Create styles
const styles = StyleSheet.create({
    page: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      positon: 'relative',
    },
    section: {
      flex: '0 0 50%',
      maxWidth: '50%',
      height: '100%',
      padding: '15px',
      alignItems: 'center',
      flexWrap: 'nowrap',
      flexDirection: 'row',
    },
    textWrap: {
      width: '100%',
      backgroundColor: '#c6e7ea',
      height: "100%",
      margin: 0,
      padding: 0,
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignItems: 'center',
    },
    textBox: {
      margin: '35px',
      textAlign: 'left',
    },
    imgWindow: {
      margin: 10,
      padding: 10,
      position: 'relative',
    },
    image: {
        width:'100px',
        height:'100px',
        objectFit: 'cover',
      },
    cells: {
      width:'100%',
      height:'100%',
      position: 'absolute',
      top: '15px',
      left: '15px'
    },
    cellsTwo: {
      width:'100%',
      height:'100%',
      position: 'absolute',
      top: '0px',
      left: '0px'
    },
    characterBox: {
      width:'50%',
      height:'50%',
      // backgroundColor: 'red',
      position: 'relative'
    },
    photo: {
      width:'100%',
      height:'100%',
      objectFit: 'contain'
      // position: 'absolute',
      // top: '0',
      // left: '0'
    },
    canvas: {
        width:'100%',
        height:'600px',
        backgroundColor: 'red',
    },
    para: {
      fontSize: 38,
      lineHeight: 1.4,
      fontWeight: 800,
      fontFamily: 'Open Sans',
    },
    title: {
      position: 'absolute',
      bottom: '14x',
      left: '10px',
      fontSize: 8,
      lineHeight: 1.4,
      opacity: 0.4
    },
  });

  const ShadowDoc = (props) => (
    <Document>
      {props.pageList.map((page, i) => (
        <Page key={i} wrap="false" size="A4" orientation="landscape" style={styles.page}>

        <View style={styles.section}>
          <Image style={styles.photo} src={{ uri: page.image, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
        </View>
        
        <View style={styles.section}>
          <View style={styles.textWrap}>
            <View style={styles.textBox}>
              <Text style={styles.para}>{page.text}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.title}>{props.title}</Text>
      </Page>
      )
      )}
  </Document>
  )

  export default ShadowDoc;