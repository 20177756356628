import React from "react";

function Search(props) {
    return (
        <form
        onSubmit={(event) => {
          event.preventDefault();
          const query = event.target.elements.query.value;
          props.searchWithFuse(query)
        }}
      >
        <input type="search" id="query" placeholder="Search Images" onChange={(event) => {
          event.preventDefault();
          const query = event.target.value;
          if(query.length === 0){
            props.searchWithFuse(query)
            }
        }}/>
        <button>Search</button>
      </form>
    );
  }

  export default Search;