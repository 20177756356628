import React from 'react';
import './image.scss'
// import { height } from 'pdfkit/js/page';

function ImageLayout(props) {
    let slide = props.content;
  return (
    <div className="preview preview--image">
        <div className="left-col">
            <div className="image-box">
                <img alt="" className="image" src={slide.image} />
            </div>
        </div>
        <div className="right-col">
            <div className="text-box">
                <p>{slide.text}</p>
            </div>
        </div>
    </div>
  )
}

export default ImageLayout;




