import React from 'react'

class TextArea extends React.Component {

  /*   state = {
      content: this.props.initContent
      } */


    handleSubmit = (value) => {
      this.props.changeText(value, this.props.field);
    }

    onChange = (e) => {
        let value = e.target.value;
        // this.setState({ content: value });
        this.handleSubmit(value);
    }


  render () {
    return(
      <>
        {/* <label>{this.props.field}</label> */}
        <textarea name={this.props.field} onChange={(e) => this.onChange(e)} value={this.props.initContent} placeholder="Add Text Here" />
      </>
    );
  }
}


export default TextArea;
