// const images = require("../image-index.json"),
//   characters = images.characters,
//   photos = images.photos,
//   objects = images.objects,
//   people = images.people;

// let ID = () => {
//   return '_' + Math.random().toString(36).substr(2, 9);
// };

var defaultSlide = {
  layout: "image",
  image: "https://sail1261.com/visaid/wp-content/uploads/2021/09/visaid-daily-activities-2-bathing-03-2048x2048.jpg",
  text: "Enter your text into the corresponding form on the right",
};

// var oldTemplate = [
//   {
//     layout: "single-person",
//     photo: photos.photo[0].filename,
//     object: objects.object[0].filename,
//     person: people.counsellor[0].filename,
//     text: "A health check is when a doctor or nurse checks if you are healthy.",
//     character: {
//       head: characters.head[0].filename,
//       hair: characters.hair[0].filename,
//       body: characters.body[0].filename,
//       speech: characters.speech[1].filename,
//     },
//   },
//   {
//     layout: "object",
//     photo: photos.photo[0].filename,
//     object: objects.object[0].filename,
//     person: people.counsellor[0].filename,
//     text: "I can ask Annie about my body. If something hurts, if I am worried…",
//     character: {
//       head: characters.head[0].filename,
//       hair: characters.hair[0].filename,
//       body: characters.body[0].filename,
//       speech: characters.speech[1].filename,
//     },
//   },
//   {
//     layout: "photo",
//     photo: photos.photo[0].filename,
//     object: objects.object[0].filename,
//     person: people.counsellor[0].filename,
//     text: "A health check is when a doctor or nurse checks if you are healthy.",
//     character: {
//       head: characters.head[0].filename,
//       hair: characters.hair[0].filename,
//       body: characters.body[0].filename,
//       speech: characters.speech[1].filename,
//     },
//   },
// ];

let templateSlides = [
  {
    layout: "image",
    image: "https://sail1261.com/visaid/wp-content/uploads/2021/09/visaid-daily-activities-2-bathing-03-2048x2048.jpg",
    text: "Enter your text into the corresponding form on the right",
  }
];

export default defaultSlide;
export { templateSlides, defaultSlide };
