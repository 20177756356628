import React from 'react';
import PageButton from './PageButton';

//props
//click
//text
//icon

class CreateHome extends React.Component {

  render () {
    return(
        <div className="create-home">
          <div className="create-home__buttons">
            <div className="create-home__copy">
              <h2>Get started with VisAid Studio</h2>
              <p>Create your own visual aid documents or images using the VisAid Studio. Simply click one option below to create <strong>new</strong> or start with a <strong>template.</strong></p>
            </div>
            <PageButton text="Create Visual Aid" icon="/static/icons/create.svg" click={this.props.createStory} />
            <PageButton text="Create Visual Aid from Template"  icon="/static/icons/template_new.svg" click={this.props.createTemplate} />
          </div>
        </div>
    )
  }
}


export default CreateHome;
