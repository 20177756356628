import React from 'react';
import {
  NavLink,
} from 'react-router-dom';


class HeaderBar extends React.Component {
  
  editTitle = () => {
    this.props.onClick();
  }

  render(){
  return (
      <nav className="head-nav">
       <NavLink exact to='/' activeClassName="active" className='head-nav__home'>
          <img src="/static/icons/home.svg" alt="" />
          <p>Home</p>
        </NavLink>
        <div className="container">
        <div className="row align-items-center">
            <div className="col-12 text-center">
              <h1 className={ (this.props.title !== 'MHIDI-Y Studio') ? 'editable' : 'default' } onClick={() => this.editTitle()}>{this.props.title}</h1>
            </div>
        </div>
        </div>
        <img className="alfred-logo" src="/static/icons/alfred-health-logo.svg" alt="Alfred Health Logo" />
      </nav>
    )
  }
}

export default HeaderBar;




