import React from 'react';
import { withRouter } from 'react-router-dom';

//props
//click
//text
//icon

class PageButton extends React.Component {

  onClick = () => {
    this.props.click();
    this.props.history.push('/story-editor');
  }

  render () {
    return(
        <div className="page-button" onClick={() => this.onClick()}>
          <img src={this.props.icon} alt={this.props.title} />
          <p>{this.props.text}</p>
        </div>
    )
  }
}


export default withRouter(PageButton);
